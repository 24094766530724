import { useCallback } from 'react';
import {
	ConnectionHandler,
	ConnectionInterface,
	type RecordProxy,
	type RecordSourceProxy,
} from 'relay-runtime';
import type { utils_realtimeUpdater_issueListDetails_Query$data as IssuesListDetailsResponse } from '@atlassian/jira-relay/src/__generated__/utils_realtimeUpdater_issueListDetails_Query.graphql.ts';
import RelayDataID from '@atlassian/relay-data-id';
import { useConnectionsList } from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	insertNewGroupInGroupsConnection,
	useUpdateConnectionDetails,
	updateParentHasChildren,
} from './utils.tsx';

type SearchViewContext = NonNullable<
	NonNullable<NonNullable<IssuesListDetailsResponse['jira']>['issuesByKey']>[number]
>['searchViewContext'];

export type SearchViewContextMapping = NonNullable<
	NonNullable<SearchViewContext>['contexts']
>[number];

export type GroupsForIssue = { __id: string; id: string; afterGroupId?: string | null }[];

export type IssuePositionUpdaterArgs = {
	store: RecordSourceProxy;
	searchViewContext: SearchViewContext;
	issueId: string;
	issueRecordId: string;
	parentIssueNodeRecordId?: string;
	groupConnectionId?: string;
	fieldSetConnectionId: string | undefined;
	projectKey?: string;
};

export type IssueDeleteUpdaterArgs = {
	store: RecordSourceProxy;
	/** Resource ID of the issue to find and remove from the store. */
	issueResourceId: string;
	groupConnectionId?: string;
	projectKey?: string;
};

const JIRA_ISSUE_TYPENAME = 'JiraIssue';

const addEdgeToConnection = ({
	store,
	connectionRecord,
	fieldSetsRecord,
	issueRecordId,
	context,
	projectKey,
}: {
	store: RecordSourceProxy;
	connectionRecord: RecordProxy<{}>;
	fieldSetsRecord: RecordProxy<{}> | null | undefined;
	issueRecordId: string;
	context: SearchViewContextMapping;
	projectKey?: string;
}) => {
	const issueRecord = store.get(issueRecordId);
	if (!issueRecord || !fieldSetsRecord) {
		return;
	}
	const edge = ConnectionHandler.createEdge(store, connectionRecord, issueRecord, 'JiraIssueEdge');
	const fieldSets = edge.getOrCreateLinkedRecord('fieldSets', 'JiraIssueFieldSetEdge', {
		first: 500,
	});
	fieldSets.copyFieldsFrom(fieldSetsRecord);

	const updateArgs = projectKey ? { projectKey } : undefined;
	const canHaveChildIssues = issueRecord.getValue('canHaveChildIssues', updateArgs);
	if (canHaveChildIssues) {
		edge.setValue(true, 'canHaveChildIssues', updateArgs);
	}

	// no before or after implies an empty conneciton, verify then create the edges list
	const { EDGES } = ConnectionInterface.get();
	const edges = connectionRecord.getLinkedRecords(EDGES);
	if (!edges || context.position === -1 || context.position == null) {
		return;
	}
	const nextEdges = edges
		.slice(0, context.position)
		.concat(edge)
		.concat(edges.slice(context.position));

	connectionRecord.setLinkedRecords(nextEdges, EDGES);
};

export const useIssueRealtimeUpdater = () => {
	const { connections } = useConnectionsList();
	const updateConnectionDetails = useUpdateConnectionDetails();

	const issuePositionUpdater = useCallback(
		({
			store,
			searchViewContext,
			issueRecordId,
			parentIssueNodeRecordId,
			fieldSetConnectionId,
			groupConnectionId,
			projectKey,
		}: IssuePositionUpdaterArgs): boolean => {
			const contexts = searchViewContext?.contexts;
			const fieldSetsRecord = fieldSetConnectionId ? store.get(fieldSetConnectionId) : undefined;
			let wasUpdated = false;

			if (!contexts?.length && parentIssueNodeRecordId) {
				// we know the parent issues id on create/update, but we need to find the edge to udpate the hasChildren field
				const parentIssueEdgeRecordId = Object.values(connections)
					.flatMap((connection) => {
						const { connectionId } = connection;
						const connectionRecord = store.get(connectionId);
						if (!connectionRecord) {
							return;
						}
						const { EDGES } = ConnectionInterface.get();
						const edges = connectionRecord.getLinkedRecords(EDGES);

						return edges;
					})
					.find((edge) => {
						const existingEdge =
							edge?.getLinkedRecord('node')?.getDataID() === parentIssueNodeRecordId;
						return existingEdge;
					})
					?.getDataID();
				if (parentIssueEdgeRecordId != null) {
					wasUpdated = updateParentHasChildren({
						store,
						parentIssueRecordId: parentIssueEdgeRecordId,
						connectionId: undefined,
						hasChildren: true,
						projectKey,
					});
				}
			}

			if (!contexts) {
				return wasUpdated;
			}

			Object.values(connections).forEach((connection) => {
				const { connectionId } = connection;
				const connectionRecord = store.get(connectionId);
				if (!connectionRecord) {
					return;
				}
				const { EDGES } = ConnectionInterface.get();
				const edges = connectionRecord.getLinkedRecords(EDGES);

				if (!edges) return false;

				const existingNodeIndex = edges.findIndex(
					(edge) => edge?.getLinkedRecord('node')?.getDataID() === issueRecordId,
				);

				const matchingContext = contexts.find((context) => {
					if (connection.type === 'PARENT_CHILDREN') {
						return context.parentIssueId === connection.parentId;
					}
					if (connection.type === 'GROUP_CHILDREN') {
						return context.jql === connection.jql;
					}
					if (connection.type === 'ROOT_ISSUES') {
						return context.parentIssueId == null && context.jql == null;
					}
					return false;
				});

				const relativeIssueIndex = edges.findIndex((edge) => {
					const issueId = edge?.getLinkedRecord('node')?.getValue('issueId');
					return (
						issueId === matchingContext?.afterIssueId || issueId === matchingContext?.beforeIssueId
					);
				});

				if (matchingContext) {
					// issue does not exist in the connection and issue to be positioned in reference to is not yet in view, so we let fetching of more issues position it
					if (edges.length > 0 && relativeIssueIndex === -1) {
						return;
					}

					// issue will exist on the connection
					if (existingNodeIndex === -1) {
						// issue is not previously on the connection, add it and update the owner if required
						addEdgeToConnection({
							store,
							connectionRecord,
							fieldSetsRecord,
							issueRecordId,
							context: matchingContext,
							projectKey,
						});
						updateConnectionDetails({
							store,
							connection,
							operation: 'add',
							remainingIssueCount: connectionRecord.getLinkedRecords('edges')?.length,
							groupConnectionId,
							projectKey,
						});

						wasUpdated = true;
						return;
					}
					if (existingNodeIndex === matchingContext.position) {
						// issue has not moved, noop
						return;
					}
					// issue has moved within the same connection
					ConnectionHandler.deleteNode(connectionRecord, issueRecordId);
					addEdgeToConnection({
						store,
						connectionRecord,
						fieldSetsRecord,
						issueRecordId,
						context: matchingContext,
						projectKey,
					});
					wasUpdated = true;
				} else if (existingNodeIndex !== -1) {
					// issue is no longer in the connection, remove it and update the owner if required
					ConnectionHandler.deleteNode(connectionRecord, issueRecordId);

					updateConnectionDetails({
						store,
						connection,
						operation: 'remove',
						remainingIssueCount: connectionRecord.getLinkedRecords('edges')?.length,
						groupConnectionId,
						projectKey,
					});
					wasUpdated = true;
				}
			});

			return wasUpdated;
		},
		[connections, updateConnectionDetails],
	);

	const issueDeleteUpdater = useCallback(
		({
			store,
			issueResourceId,
			groupConnectionId,
			projectKey,
		}: IssueDeleteUpdaterArgs): boolean => {
			const id = RelayDataID({ id: issueResourceId }, JIRA_ISSUE_TYPENAME);
			let wasDeleted = false;

			if (!id) {
				return wasDeleted;
			}

			Object.values(connections).forEach((connection) => {
				const connectionRecord = store.get(connection.connectionId);
				if (!connectionRecord) {
					return;
				}

				// check if the issue is in the connection
				if (
					connectionRecord
						.getLinkedRecords('edges')
						?.some((edge) => edge?.getLinkedRecord('node')?.getDataID() === id)
				) {
					ConnectionHandler.deleteNode(connectionRecord, id);

					updateConnectionDetails({
						store,
						connection,
						operation: 'remove',
						remainingIssueCount: connectionRecord.getLinkedRecords('edges')?.length,
						groupConnectionId,
						projectKey,
					});
					wasDeleted = true;
				}
			});

			return wasDeleted;
		},
		[connections, updateConnectionDetails],
	);

	const groupsForIssueUpdater = useCallback(
		({
			store,
			groupConnectionId,
			groupsForIssue,
		}: {
			store: RecordSourceProxy;
			groupConnectionId?: string;
			groupsForIssue: GroupsForIssue;
		}): boolean => {
			let wasUpdated = false;

			if (!groupConnectionId) {
				return wasUpdated;
			}

			const groupsConnection = store.get(groupConnectionId);
			if (!groupsConnection) {
				return wasUpdated;
			}

			let groupEdges = groupsConnection.getLinkedRecords('edges') || [];
			const existingGroupIds = new Set(
				groupEdges?.map((edge) => edge?.getLinkedRecord('node')?.getValue('id')),
			);

			groupsForIssue.forEach((group) => {
				const newGroup = store.get(group.__id);
				if (!existingGroupIds.has(group.id) && newGroup) {
					groupEdges = insertNewGroupInGroupsConnection({
						store,
						groupsConnection,
						newGroup,
						groupEdges,
						afterGroupId: group.afterGroupId,
					});

					wasUpdated = true;
				}
			});

			groupsConnection.setLinkedRecords(groupEdges, 'edges');

			return wasUpdated;
		},
		[],
	);

	return { issuePositionUpdater, issueDeleteUpdater, groupsForIssueUpdater };
};
