import { commitLocalUpdate, ConnectionHandler, type Environment } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import type { highlightIssueRow_update_isHighlightedIssueRow$key as IssueRef } from '@atlassian/jira-relay/src/__generated__/highlightIssueRow_update_isHighlightedIssueRow.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { highlightIssueRow } from '../highlight-issue-row/index.tsx';
import { insertEdgeAfter, insertEdgeBefore, hasEdge } from './utils/insert-edge.tsx';

// How long the issue row highlight should remain visible after the issue was successfully created
const HIGHLIGHT_LINGER_DURATION_MS = 2000;

export type IssueInsertionArgs = {
	/**
	 * When `true` the issue is inserted at the end of the connection, or after the specified cursor. When `false` the
	 * issue is inserted at the beginning of the connection, or before the specified cursor. Defaults to `true`.
	 */
	insertAfter?: boolean;
	/**
	 * ID of the node in the connection the issue should be inserted relative to. Note this refers to the `id` field,
	 * not the Relay Data ID.
	 */
	insertNodeId?: string;

	/**
	 * It retrieve the real id of an optimistic created one, if this on exists
	 * @param {string} optimisticId - Potential optimistic id
	 * @returns {string | undefined} The real id whose the optimistic id is associated
	 */
	getRealId?: (optimisticId: string) => string | undefined;
};

export type IssueConnectionArgs = IssueInsertionArgs & {
	/** Fragment reference for the `JiraIssue` to update. */
	issue: IssueRef;
	/** ID of the Relay connection where the new issue should be inserted. */
	issueConnectionId: string;
	/** The ID of the Relay connection for the parent issue where the new issue should be inserted. */
	parentItemConnectionId?: string;
	/** ID of the new issue record in the Relay store. */
	issueRecordId: string;
	/** Key of the field sets connection that has been fetched for the issue. */
	fieldSetsConnectionId: string;
	/** Optional project key to update the parent issue in the Relay store after creating a child issue. */
	projectKey?: string | null;
};

/**
 * Updater function called when a new issue has been appended to an issue connection in the table. This will highlight
 * the issue row and update an issues field sets data in the Relay store so it can be rendered within the row.
 *
 * @param store Relay store proxy, sourced from either a mutation updater function or `commitLocalUpdate`.
 */
export const issueConnectionUpdater = (
	store: RecordSourceSelectorProxy,
	{
		issue,
		issueConnectionId,
		parentItemConnectionId,
		issueRecordId,
		fieldSetsConnectionId,
		insertAfter = true,
		insertNodeId,
		getRealId = (id) => id,
		projectKey,
	}: IssueConnectionArgs,
) => {
	highlightIssueRow(store, issue, true);

	const issueRecord = store.get(issueRecordId);
	const connectionRecord = store.get(issueConnectionId);
	const fieldSetsRecord = store.get(fieldSetsConnectionId);

	if (!issueRecord || !connectionRecord || !fieldSetsRecord) {
		return;
	}

	if (parentItemConnectionId) {
		const updateArgs = projectKey ? { filterByProjectKeys: [projectKey] } : undefined;
		store.get(parentItemConnectionId)?.setValue(true, 'hasChildren', updateArgs);
	}

	const edge = ConnectionHandler.createEdge(store, connectionRecord, issueRecord, 'JiraIssueEdge');
	// don't insert the same edge in case optimisticUpdater gets called multiple times
	if (hasEdge(connectionRecord, edge) && fg('jsc_m2_hierarchy_fe_changes')) {
		return;
	}

	if (fg('jsc_canhavechildren_edge_update')) {
		const updateArgs = projectKey ? { projectKey } : undefined;
		const canHaveChildIssues = issueRecord.getValue('canHaveChildIssues', updateArgs);
		if (canHaveChildIssues) {
			edge.setValue(true, 'canHaveChildIssues', updateArgs);
		}
	}

	let realNodeId = insertNodeId;
	if (insertNodeId != null) {
		realNodeId = getRealId(insertNodeId) ?? insertNodeId;
	}

	if (insertAfter) {
		insertEdgeAfter(connectionRecord, edge, realNodeId);
	} else {
		insertEdgeBefore(connectionRecord, edge, realNodeId);
	}
	const fieldSets = edge.getOrCreateLinkedRecord('fieldSets', 'JiraIssueFieldSetEdge', {
		first: 500,
	});
	fieldSets.copyFieldsFrom(fieldSetsRecord);

	const totalIssues = connectionRecord.getValue('totalIssueSearchResultCount');

	if (totalIssues != null && typeof totalIssues === 'number') {
		connectionRecord.setValue(totalIssues + 1, 'totalIssueSearchResultCount');
	}
};

export const removeHighlightAfterDelay = (environment: Environment, issue: IssueRef) => {
	setTimeout(() => {
		commitLocalUpdate(environment, (store) => {
			highlightIssueRow(store, issue, false);
		});
	}, HIGHLIGHT_LINGER_DURATION_MS);
};
