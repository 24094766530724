import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { CascadingSelectReadView } from '@atlassian/jira-issue-field-cascading-select-readview-full/src/ui/cascading-select/index.tsx';
import type { cascadingSelect_nativeIssueTable_CascadingSelectCell$key } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_nativeIssueTable_CascadingSelectCell.graphql';

export type Props = {
	fieldRef: cascadingSelect_nativeIssueTable_CascadingSelectCell$key;
};

export const CascadingSelectCell = ({ fieldRef }: Props) => {
	const data = useFragment<cascadingSelect_nativeIssueTable_CascadingSelectCell$key>(
		graphql`
			fragment cascadingSelect_nativeIssueTable_CascadingSelectCell on JiraCascadingSelectField {
				...cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView
			}
		`,
		fieldRef,
	);

	return <CascadingSelectReadView isTruncated fragmentRef={data} />;
};
