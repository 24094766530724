/**
 * @generated SignedSource<<9f82ea17f1b93eee67d8f48502ae1a9c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_nativeIssueTable_CascadingSelectCell$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView">;
  readonly " $fragmentType": "cascadingSelect_nativeIssueTable_CascadingSelectCell";
};
export type cascadingSelect_nativeIssueTable_CascadingSelectCell$key = {
  readonly " $data"?: cascadingSelect_nativeIssueTable_CascadingSelectCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_nativeIssueTable_CascadingSelectCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cascadingSelect_nativeIssueTable_CascadingSelectCell",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView"
    }
  ],
  "type": "JiraCascadingSelectField"
};

(node as any).hash = "a11053c48bf8f3c33c4eace78b9be6e1";

export default node;
