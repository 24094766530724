import React, { useCallback, useMemo, useState } from 'react';

import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';

import type {
	Option,
	RadioSelectEditViewProps,
} from '@atlassian/jira-issue-field-radio-select-editview-full/src/ui/radio-select/types.tsx';
import RadioSelectEditViewEntryPoint from '@atlassian/jira-issue-field-radio-select-editview-full/src/entrypoint.tsx';
import { RadioSelectReadView } from '@atlassian/jira-issue-field-radio-select-readview-full/src/ui/radio-select/index.tsx';
import type { radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation as RadioSelectMutation } from '@atlassian/jira-relay/src/__generated__/radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation.graphql';
import type { radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditView_fragmentRef$key as RadioSelectFragment } from '@atlassian/jira-relay/src/__generated__/radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditView_fragmentRef.graphql';
import type { radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef$key as RadioSelectWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type {
	RadioSelectInlineEditViewProps,
	RadioSelectInlineEditViewWithIsEditableProps,
} from './types.tsx';
/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: Option | null, b: Option | null) => a?.value === b?.value;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `RadioSelectInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [RadioSelectInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const RadioSelectInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	maxLines,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: RadioSelectInlineEditViewWithIsEditableProps) => {
	const data = useFragment<RadioSelectWithIsEditableFragment>(
		graphql`
			fragment radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef on JiraRadioSelectField {
				id
				name
				fieldId
				type
				...radioSelect_issueFieldRadioSelectReadviewFull_RadioSelectReadView
				selectedOption {
					value: id
					label: value
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<RadioSelectMutation>(graphql`
		mutation radioSelect_issueFieldRadioSelect_RadioSelectField_Mutation(
			$input: JiraUpdateRadioSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateRadioSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, name, selectedOption, fieldId, type: fieldType } = data;

	const initialValue: Option | null = useMemo(() => {
		if (!selectedOption?.label) {
			return null;
		}

		return {
			value: selectedOption?.value ?? '',
			label: selectedOption?.label ?? '',
		};
	}, [selectedOption]);

	const [updatedValue, setUpdatedValue] = useState<Option | null>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const handleSubmit = useCallback(
		(option: Option | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(option);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: option?.value ?? null,
						},
					},
				},
				onCompleted(response) {
					if (response.jira?.updateRadioSelectField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateRadioSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedOption: {
									id: option?.value ?? '',
									label: option?.label ?? '',
									value: option?.value ?? '',
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	const renderReadView = useCallback(
		() => <RadioSelectReadView fragmentRef={data} maxLines={maxLines} />,
		[data, maxLines],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): RadioSelectEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChangeAndConfirm,
		spacing,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={RadioSelectEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [RadioSelectInlineEditViewProps](./types.tsx)
 */
export const RadioSelectInlineEditView = ({
	fragmentRef,
	...props
}: RadioSelectInlineEditViewProps) => {
	/**
	 * This fragment should spread the inner InlineEditViewWithIsEditable fragment and read the isEditable flag from the
	 * fieldConfig.
	 */
	const data = useFragment<RadioSelectFragment>(
		graphql`
			fragment radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditView_fragmentRef on JiraRadioSelectField {
				...radioSelect_issueFieldRadioSelectInlineEditFull_RadioSelectInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<RadioSelectInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
