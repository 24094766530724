/**
 * @generated SignedSource<<f4c9b1f861f4ab0d8df71893bc569a6d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly fieldSetId: string | null | undefined;
      readonly fields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly fieldId: string;
            readonly type: string;
            readonly " $fragmentSpreads": FragmentRefs<"affectedServices_nativeIssueTable_AffectedServicesCell" | "cascadingSelect_nativeIssueTable_CascadingSelectCell" | "checkboxSelect_nativeIssueTable_CheckboxSelectCell" | "cmdbObjects_nativeIssueTable_CmdbObjectsCell" | "commentSummary_nativeIssueTable_CommentSummaryCell" | "components_nativeIssueTable_ComponentsCell_fieldRef" | "components_nativeIssueTable_ComponentsInlineEditCell_fieldRef" | "dateTime_nativeIssueTable_DateTimeCell" | "date_nativeIssueTable_DateCell" | "date_nativeIssueTable_DateInlineEditCell_fieldRef" | "epicLink_nativeIssueTable_EpicLinkCell" | "fallback_nativeIssueTable_FallbackCell" | "issueKey_nativeIssueTable_IssueKeyCell_fieldRef" | "issueLinks_nativeIssueTable_IssueLinksCell" | "issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell" | "issueStatus_nativeIssueTable_IssueStatusCell" | "issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef" | "issueType_nativeIssueTable_IssueTypeCell" | "labels_nativeIssueTable_LabelsCell" | "multiSelect_nativeIssueTable_MultiSelectCell" | "multiUserPicker_nativeIssueTable_MultiUserPickerCell" | "multiVersionPicker_nativeIssueTable_MultiVersionPickerCell" | "number_nativeIssueTable_NumberCell_fieldRef" | "originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef" | "parent_nativeIssueTable_ParentCell_fieldRef" | "plainText_nativeIssueTable_PlainTextCellOld_fieldRef" | "plainText_nativeIssueTable_PlainTextCell_fieldRef" | "priority_nativeIssueTable_PriorityCell" | "project_nativeIssueTable_ProjectCell" | "radioSelect_nativeIssueTable_RadioSelectCell" | "requestType_nativeIssueTable_RequestTypeCell" | "resolution_nativeIssueTable_ResolutionCell" | "richText_nativeIssueTable_RichTextCell_fieldRef" | "singleSelect_nativeIssueTable_SingleSelectCell" | "sprint_nativeIssueTable_SprintCell" | "team_nativeIssueTable_TeamCell" | "url_nativeIssueTable_UrlCell" | "user_nativeIssueTable_UserCell_fieldRef" | "versions_nativeIssueTable_VersionsCell">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly type: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets";
};
export type issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$key = {
  readonly " $data"?: issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "type"
},
v1 = [
  {
    "kind": "Variable",
    "name": "isInlineEditingEnabled",
    "variableName": "isInlineEditingEnabled"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    }
  ],
  "kind": "Fragment",
  "name": "issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets",
  "selections": [
    {
      "concreteType": "JiraIssueFieldSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "fieldSetId"
            },
            {
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fields",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "fieldId"
                        },
                        (v0/*: any*/),
                        {
                          "kind": "FragmentSpread",
                          "name": "affectedServices_nativeIssueTable_AffectedServicesCell"
                        },
                        {
                          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "kind": "FragmentSpread",
                              "name": "cascadingSelect_nativeIssueTable_CascadingSelectCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "components_nativeIssueTable_ComponentsInlineEditCell_fieldRef"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "dateTime_nativeIssueTable_DateTimeCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "plainText_nativeIssueTable_PlainTextCell_fieldRef"
                            },
                            {
                              "kind": "FragmentSpread",
                              "name": "commentSummary_nativeIssueTable_CommentSummaryCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "url_nativeIssueTable_UrlCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "singleSelect_nativeIssueTable_SingleSelectCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "multiSelect_nativeIssueTable_MultiSelectCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "checkboxSelect_nativeIssueTable_CheckboxSelectCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "multiUserPicker_nativeIssueTable_MultiUserPickerCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "team_nativeIssueTable_TeamCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "originalEstimate_nativeIssueTable_OriginalEstimateCell_fieldRef"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "radioSelect_nativeIssueTable_RadioSelectCell"
                            },
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "multiVersionPicker_nativeIssueTable_MultiVersionPickerCell"
                            }
                          ]
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "cmdbObjects_nativeIssueTable_CmdbObjectsCell"
                        },
                        {
                          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                          "kind": "Condition",
                          "passingValue": false,
                          "selections": [
                            {
                              "kind": "FragmentSpread",
                              "name": "components_nativeIssueTable_ComponentsCell_fieldRef"
                            },
                            {
                              "kind": "FragmentSpread",
                              "name": "plainText_nativeIssueTable_PlainTextCellOld_fieldRef"
                            }
                          ]
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "date_nativeIssueTable_DateCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "date_nativeIssueTable_DateInlineEditCell_fieldRef"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "epicLink_nativeIssueTable_EpicLinkCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "fallback_nativeIssueTable_FallbackCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "issueKey_nativeIssueTable_IssueKeyCell_fieldRef"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "issueStatusCategory_nativeIssueTable_IssueStatusCategoryCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "issueStatus_nativeIssueTable_IssueStatusCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "issueSummary_nativeIssueTable_IssueSummaryCell_fieldRef"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "labels_nativeIssueTable_LabelsCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "issueType_nativeIssueTable_IssueTypeCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "parent_nativeIssueTable_ParentCell_fieldRef"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "priority_nativeIssueTable_PriorityCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "project_nativeIssueTable_ProjectCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "resolution_nativeIssueTable_ResolutionCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "sprint_nativeIssueTable_SprintCell"
                        },
                        {
                          "args": (v1/*: any*/),
                          "kind": "FragmentSpread",
                          "name": "user_nativeIssueTable_UserCell_fieldRef"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "versions_nativeIssueTable_VersionsCell"
                        },
                        {
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "isDensityFull",
                              "variableName": "isDensityFull"
                            }
                          ],
                          "kind": "FragmentSpread",
                          "name": "richText_nativeIssueTable_RichTextCell_fieldRef"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "requestType_nativeIssueTable_RequestTypeCell"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "issueLinks_nativeIssueTable_IssueLinksCell"
                        },
                        {
                          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                          "kind": "Condition",
                          "passingValue": true,
                          "selections": [
                            {
                              "args": (v1/*: any*/),
                              "kind": "FragmentSpread",
                              "name": "number_nativeIssueTable_NumberCell_fieldRef"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueFieldSetConnection"
};
})();

(node as any).hash = "494d0bb165eadad0b426d567d3446f78";

export default node;
