import React, {
	Fragment,
	memo,
	type PropsWithChildren,
	type RefObject,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { token, type CSSToken } from '@atlaskit/tokens';
import { ScreenReaderCaption } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useVisibleDepth } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import type { Props as AsyncColumnPickerProps } from '@atlassian/jira-issue-table-column-picker/src/ui/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { InsertColumnButton } from '../insert-column-button/index.tsx';
import {
	COLUMN_ID_MEATBALL_MENU,
	COLUMN_ID_EXPAND_BUTTON,
	MOVE_COLUMN_FIRST,
	MOVE_COLUMN_LAST,
	MOVE_COLUMN_LEFT,
	MOVE_COLUMN_RIGHT,
} from '../../../common/constants.tsx';
import { getExpandColumnWidth } from '../utils.tsx';
import type {
	HeaderHeight,
	Column,
	ColumnId,
	ReorderColumn,
	TargetColumnPosition,
	BorderWidth,
	ColumnResizeHandleType,
} from '../../../common/types.tsx';

import { useCellWrapper } from '../../../common/ui/cell-navigation/cell-wrapper/index.tsx';
import { useElementSizeSelector } from '../../../controllers/element-size/index.tsx';
import {
	useIsFixedTableLayoutEnabled,
	useIsInBetweenColumnPickerEnabled,
	useIsInlineFieldConfigEnabled,
} from '../../../controllers/features/selectors.tsx';
import { useScrollStateSelector } from '../../../controllers/scroll-state/index.tsx';
import {
	InlineFieldCreateOnboardingProvider,
	InlineFieldCreateOnboarding,
} from '../../inline-field-create-onboarding/index.tsx';
import { HeaderCell } from './header-cell/index.tsx';
import messages from './messages.tsx';

const BORDER_WIDTH: BorderWidth = '1px';
const RESIZE_HANDLE_Z_INDEX = 1;

const MaybeInlineFieldCreateOnboardingProvider = componentWithCondition(
	() => fg('jira_inline_field_config_gate'),
	InlineFieldCreateOnboardingProvider,
	({ children }: PropsWithChildren<{}>) => children,
);

const baseHeaderContainerCellStyles = {
	backgroundColor: token('elevation.surface.sunken'),
	borderColor: token('color.border'),
	borderStyle: 'solid',
	borderTopWidth: 0,
	borderBottomWidth: BORDER_WIDTH,
	borderLeftWidth: 0,
	borderRightWidth: BORDER_WIDTH,
	'&:first-of-type': {
		borderTopLeftRadius: token('border.radius.200'),
	},
	'&:last-of-type': {
		borderTopRightRadius: token('border.radius.200'),
		borderRightWidth: 0,
	},
} as const;
const HEADER_HEIGHT: HeaderHeight = 40;
const STICKY_CELL_Z_INDEX = RESIZE_HANDLE_Z_INDEX + 1;

type Props = {
	columns: Column[];
	canViewColumnConfiguration: boolean;
	canEditColumnConfiguration: boolean;
	isCompactColumnEnabled?: boolean;
	resizeHandleType?: ColumnResizeHandleType;
	onColumnsChange?: (columns: Column[]) => void;
	onResizeColumn?: (columnId: ColumnId, width: number | undefined) => void;
	tableRef?: RefObject<HTMLTableElement>;
	tableBorderRadius?: CSSToken;
	columnPickerProps?: AsyncColumnPickerProps;
};

/**
 * Runs a flash animation on the background color of the column that has been reordered,
 * in order to highlight where the column has moved to.
 */
const triggerColumnFlash = (table: HTMLElement, index: number) => {
	const columnCellsSelector = `th:nth-of-type(${index + 1}), td:nth-of-type(${index + 1})`;

	Array.from(table.querySelectorAll(columnCellsSelector))
		.filter((element): element is HTMLElement => element instanceof HTMLElement)
		.forEach((element) => triggerPostMoveFlash(element));
};

type ReorderOperation = {
	finishIndex: number;
};

export const Header = memo<Props>(
	({
		columnPickerProps,
		columns,
		canViewColumnConfiguration,
		canEditColumnConfiguration,
		isCompactColumnEnabled = false,
		resizeHandleType,
		onColumnsChange,
		onResizeColumn,
		tableRef,
		tableBorderRadius,
	}: Props) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		// Unique identifier for the header of this particular issue table instance
		const [instanceId] = useState(() => Symbol('pdnd-issue-table-header'));

		const [lastReorderOperation, setLastReorderOperation] = useState<ReorderOperation | null>(null);

		const [screenReaderMessage, setScreenReaderMessage] = useState('');

		const reorderColumn: ReorderColumn = useCallback(
			({ startIndex, indexOfTarget, closestEdgeOfTarget = null, analyticsAttributes = {} }) => {
				const finishIndex = getReorderDestinationIndex({
					axis: 'horizontal',
					startIndex,
					indexOfTarget,
					closestEdgeOfTarget,
				});

				if (startIndex === finishIndex) {
					return;
				}

				const newColumns = reorder({
					list: columns,
					startIndex,
					finishIndex,
				});

				onColumnsChange?.(newColumns);

				setLastReorderOperation({ finishIndex });

				setScreenReaderMessage(
					formatMessage(messages.movedColumn, {
						column: columns[startIndex]?.title,
						previousPosition: startIndex + 1,
						position: finishIndex + 1,
						// TODO: EM-7165 update to columns.length when excluding column picker
						total: columns.length - 1,
					}),
				);

				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'reordered',
						actionSubject: 'columns',
					}),
					'issueTable',
					analyticsAttributes,
				);
			},
			[columns, createAnalyticsEvent, formatMessage, onColumnsChange],
		);

		// We use an object for lastReorderOperation so we can trigger the column flash
		// if the finish index is the same as the previous reorder operation.
		useEffect(() => {
			const table = tableRef?.current;

			if (table && lastReorderOperation !== null) {
				triggerColumnFlash(table, lastReorderOperation.finishIndex);
			}
		}, [lastReorderOperation, tableRef]);

		useEffect(() => {
			if (!canEditColumnConfiguration) {
				return;
			}

			return monitorForElements({
				canMonitor({ source }) {
					// Only monitor this particular instance id
					// (i.e. prevent monitoring entities that are not headers, or headers from another issue table)
					return source.data.instanceId === instanceId;
				},
				onDrop({ source, location }) {
					const [destination] = location.current.dropTargets;

					if (!destination) {
						return;
					}

					const startIndex = columns.findIndex((column) => column.id === source.data.id);

					if (startIndex === -1) {
						return;
					}

					const indexOfTarget = columns.findIndex((column) => column.id === destination.data.id);

					if (indexOfTarget === -1) {
						return;
					}

					const closestEdgeOfTarget = extractClosestEdge(destination.data);

					reorderColumn({
						startIndex,
						indexOfTarget,
						closestEdgeOfTarget,
						analyticsAttributes: { operation: 'drop' },
					});
				},
			});
		}, [columns, canEditColumnConfiguration, instanceId, reorderColumn, tableRef]);

		const onMoveColumn = (index: number, position: TargetColumnPosition) => {
			if (canEditColumnConfiguration && onColumnsChange !== undefined) {
				switch (position) {
					case MOVE_COLUMN_FIRST: {
						if (index > 0) {
							reorderColumn({
								startIndex: index,
								indexOfTarget: 0,
								closestEdgeOfTarget: 'left',
								analyticsAttributes: { operation: 'move-first' },
							});
						}
						break;
					}
					case MOVE_COLUMN_LEFT: {
						if (index > 0) {
							reorderColumn({
								startIndex: index,
								indexOfTarget: index - 1,
								closestEdgeOfTarget: 'left',
								analyticsAttributes: { operation: 'move-left' },
							});
						}
						break;
					}
					case MOVE_COLUMN_RIGHT: {
						// TODO: EM-7165 change this to `columns.length - 1` when excluding column picker
						if (index < columns.length - 2) {
							reorderColumn({
								startIndex: index,
								indexOfTarget: index + 1,
								closestEdgeOfTarget: 'right',
								analyticsAttributes: { operation: 'move-right' },
							});
						}
						break;
					}
					case MOVE_COLUMN_LAST: {
						// TODO: EM-7165 change this to `columns.length - 1` when excluding column picker
						if (index < columns.length - 2) {
							reorderColumn({
								startIndex: index,
								// TODO: EM-7165 change this to `columns.length - 1` when excluding column picker
								indexOfTarget: columns.length - 2,
								closestEdgeOfTarget: 'right',
								analyticsAttributes: { operation: 'move-last' },
							});
						}
						break;
					}
					default:
						throw new Error(`Unexpected target column position ${position}`);
				}
			}
		};

		const onRemoveColumn = (index: number) => {
			if (canEditColumnConfiguration && onColumnsChange !== undefined) {
				const newColumns = [...columns];
				newColumns.splice(index, 1);
				onColumnsChange(newColumns);
			}
		};

		const tableWidth = useElementSizeSelector((size) => size.width);

		const { hasShadowBottom, hasShadowLeft } = useScrollStateSelector((scrollState) => {
			const { scrollTop, scrollLeft, width: containerWidth } = scrollState;

			return {
				hasShadowBottom: scrollTop > 0,
				// Scroll measurements are fractional, while width measurements are integer, so we need to round before comparing
				hasShadowLeft:
					containerWidth < tableWidth && Math.round(scrollLeft) < tableWidth - containerWidth,
			};
		});

		const isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
		const isInBetweenColumnPickerEnabled = useIsInBetweenColumnPickerEnabled();
		const isInlineFieldConfigEnabled = useIsInlineFieldConfigEnabled();

		let visibleDepth = 0;
		if (fg('jsc_m2_hierarchy_fe_changes')) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			visibleDepth = useVisibleDepth();
		}

		return (
			<>
				<MaybeInlineFieldCreateOnboardingProvider>
					{isInBetweenColumnPickerEnabled && (
						<InsertColumnButton columns={columns} columnPickerProps={columnPickerProps} />
					)}
					{(isInlineFieldConfigEnabled || isInBetweenColumnPickerEnabled) &&
						fg('jira_inline_field_config_gate') && <InlineFieldCreateOnboarding />}
				</MaybeInlineFieldCreateOnboardingProvider>
				<ScreenReaderCaption
					aria-live="assertive"
					data-testid="native-issue-table.ui.issue-table.header.screen-reader-caption"
				>
					{screenReaderMessage}
				</ScreenReaderCaption>
				<StickyHeader hasShadow={hasShadowBottom}>
					<tr>
						{columns.map((column, index) => {
							const isExpandColumn =
								column.id === COLUMN_ID_EXPAND_BUTTON && fg('jsc_m2_hierarchy_fe_changes');

							const defaultColumnWidth = column.customWidth ?? column.defaultWidth;
							const columnWidth = isExpandColumn
								? getExpandColumnWidth(visibleDepth)
								: defaultColumnWidth;

							return (
								// TODO: EM-7165 – refactor column picker logic, separating it from regular columns
								<Fragment key={column.id}>
									{
										// Empty cell to fill remaining space if configured columns are not wide enough to fill the table
										isFixedTableLayoutEnabled && column.id === COLUMN_ID_MEATBALL_MENU && (
											<EmptyHeaderCell aria-hidden />
										)
									}
									{column.customHeader ? (
										<HeaderCellContainer
											scope="col"
											columnWidth={columnWidth}
											// aria-label is added to prevent the extra accessibility hints in the header cell from being
											// read as the column title when a user is navigating between columns in the table content.
											aria-label={column.title}
											isSticky={canViewColumnConfiguration}
											hasShadow={
												isExpandColumn ? false : canViewColumnConfiguration && hasShadowLeft
											}
											visualRefresh={isVisualRefreshEnabled()}
											isCompactColumnEnabled={isCompactColumnEnabled}
											tableBorderRadius={tableBorderRadius}
											index={index}
										>
											{column.customHeader}
										</HeaderCellContainer>
									) : (
										<HeaderCell
											column={column}
											hasColumnConfiguration={canEditColumnConfiguration}
											instanceId={instanceId}
											isCompactColumnEnabled={isCompactColumnEnabled}
											isFirst={index === 0}
											// TODO: EM-7165 change this to `columns.length - 1` when excluding column picker
											isLast={index === columns.length - 2}
											resizeHandleType={resizeHandleType}
											tableRef={tableRef}
											tableBorderRadius={tableBorderRadius}
											onMoveColumn={(position) => onMoveColumn(index, position)}
											onRemoveColumn={() => onRemoveColumn(index)}
											onResizeColumn={onResizeColumn}
											index={index}
										/>
									)}
								</Fragment>
							);
						})}
					</tr>
				</StickyHeader>
			</>
		);
	},
);

const HeaderCellContainerNew = ({
	index,
	children,
	...props
}: {
	canViewColumnConfiguration?: boolean;
	columnWidth?: number;
	minColumnWidth?: number;
	isDraggable?: boolean;
	isHovered?: boolean;
	isSticky?: boolean;
	hasShadow?: boolean;
	visualRefresh: boolean;
	isCompactColumnEnabled: boolean;
	index: number;
	children: React.ReactNode;
}) => {
	const { cellRef } = useCellWrapper({
		row: 0,
		col: index,
	});

	return (
		<HeaderCellContainerStyledCell {...props} ref={cellRef}>
			{children}
		</HeaderCellContainerStyledCell>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StickyHeader = styled.thead<{ hasShadow: boolean }>(
	{
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		hasShadow && {
			boxShadow: `
                0px 8px 8px -8px ${token('elevation.shadow.overflow.spread')},
                0px 1px 1px -1px ${token('elevation.shadow.overflow.perimeter')}
            `,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HeaderCellContainerStyledCell = styled.th<{
	columnWidth?: number;
	minColumnWidth?: number;
	isDraggable?: boolean;
	isHovered?: boolean;
	isSticky?: boolean;
	hasShadow?: boolean;
	visualRefresh: boolean;
	isCompactColumnEnabled: boolean;
	tableBorderRadius?: CSSToken;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Fix for compiled prop bug with inheritance
	baseHeaderContainerCellStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ tableBorderRadius }) => ({
		position: 'relative',
		boxSizing: 'border-box',
		height: `${HEADER_HEIGHT}px`,
		maxHeight: `${HEADER_HEIGHT}px`,
		minHeight: `${HEADER_HEIGHT}px`,
		paddingBlock: token('space.0'),
		paddingInline: token('space.100'),
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			borderTopLeftRadius: `${tableBorderRadius ?? token('space.100')}`,
			// Overrides @atlaskit/css-reset styles
			paddingLeft: token('space.100'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			borderTopRightRadius: `${tableBorderRadius ?? token('space.100')}`,
			// Overrides @atlaskit/css-reset styles
			paddingRight: token('space.100'),
		},
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ columnWidth, minColumnWidth, isCompactColumnEnabled }) => ({
		// Width allows overrides when table is in fixed layout mode
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'--local-column-width': columnWidth != null ? `${columnWidth}px` : 'auto',

		width: 'var(--local-resizing-width, var(--local-column-width))',
		// Min-width allows overrides when table is in auto layout mode
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'--local-column-min-width': minColumnWidth != null ? `${minColumnWidth}px` : 'auto',
		minWidth: 'var(--local-resizing-width, var(--local-column-min-width))',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:first-of-type': {
			paddingLeft:
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
				isCompactColumnEnabled && columnWidth && columnWidth < 40 ? '0px' : token('space.100'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:last-of-type': {
			paddingRight:
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
				isCompactColumnEnabled && columnWidth && columnWidth < 40 ? '0px' : token('space.100'),
		},
	}),
	// Programmatic `isHovered` state is used here so hover state isn't displayed when hovering the resize handle
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDraggable, isHovered }) =>
		isDraggable &&
		isHovered && {
			':hover': {
				cursor: 'grab',
				backgroundColor: token('elevation.surface.hovered'),
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSticky }) =>
		isSticky && {
			position: 'sticky',
			right: 0,

			zIndex: STICKY_CELL_Z_INDEX,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasShadow }) =>
		hasShadow && {
			boxShadow: `
                -8px 0 8px -8px ${token('elevation.shadow.overflow.spread')},
                -1px 0 1px -1px ${token('elevation.shadow.overflow.perimeter')}
            `,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			borderLeftWidth: `${BORDER_WIDTH} !important`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh
			? { color: token('color.text.subtle'), font: token('font.heading.xxsmall') }
			: {
					color: token('color.text.accent.gray'),
					fontWeight: token('font.weight.medium'),
				},
);

const HeaderCellContainer = componentWithFG(
	'jira_list_grid_pattern_keyboard_nav',
	HeaderCellContainerNew,
	HeaderCellContainerStyledCell,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EmptyHeaderCell = styled.th(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Fix for compiled prop bug with inheritance
	baseHeaderContainerCellStyles,
	{
		borderLeftWidth: 0,
		borderRightWidth: 0,
	},
);
